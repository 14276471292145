export default function HeartStraightIcon({
  className
}: {
  className: string;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M20.3774 12.5031L11.9999 21.0006L3.62239 12.5031C2.74319 11.6239 2.24927 10.4315 2.24927 9.18812C2.24927 7.94475 2.74319 6.75231 3.62239 5.87312C4.50158 4.99393 5.69402 4.5 6.93739 4.5C8.18075 4.5 9.37319 4.99393 10.2524 5.87312L11.9999 7.50062L13.7474 5.87312C14.1827 5.43779 14.6995 5.09246 15.2683 4.85686C15.8371 4.62126 16.4467 4.5 17.0624 4.5C17.678 4.5 18.2877 4.62126 18.8564 4.85686C19.4252 5.09246 19.9421 5.43779 20.3774 5.87312C20.8127 6.30845 21.158 6.82526 21.3936 7.39405C21.6292 7.96284 21.7505 8.57247 21.7505 9.18812C21.7505 9.80377 21.6292 10.4134 21.3936 10.9822C21.158 11.551 20.8127 12.0678 20.3774 12.5031Z"
      />
      <path d="M20.9064 5.3445C19.8874 4.32811 18.5075 3.75655 17.0683 3.75479C15.6291 3.75303 14.2478 4.32123 13.2264 5.33512L12.0001 6.47419L10.7729 5.33137C9.75176 4.31307 8.3679 3.74212 6.92577 3.74415C5.48365 3.74617 4.10139 4.32099 3.08308 5.34216C2.06478 6.36332 1.49383 7.74718 1.49585 9.18931C1.49788 10.6314 2.0727 12.0137 3.09386 13.032L11.4704 21.5314C11.5402 21.6022 11.6234 21.6585 11.7151 21.6969C11.8069 21.7353 11.9053 21.7551 12.0048 21.7551C12.1043 21.7551 12.2027 21.7353 12.2945 21.6969C12.3862 21.6585 12.4694 21.6022 12.5392 21.5314L20.9064 13.032C21.9254 12.0124 22.4978 10.6298 22.4978 9.18825C22.4978 7.74669 21.9254 6.36414 20.9064 5.3445ZM19.8423 11.9782L12.0001 19.932L4.15324 11.9707C3.41478 11.2323 2.99991 10.2307 2.99991 9.18637C2.99991 8.14203 3.41478 7.14046 4.15324 6.402C4.8917 5.66354 5.89327 5.24867 6.93761 5.24867C7.98196 5.24867 8.98353 5.66354 9.72199 6.402L9.74074 6.42075L11.4892 8.04731C11.628 8.17647 11.8105 8.24827 12.0001 8.24827C12.1897 8.24827 12.3723 8.17647 12.5111 8.04731L14.2595 6.42075L14.2782 6.402C15.0172 5.66403 16.019 5.24985 17.0634 5.25055C18.1077 5.25125 19.109 5.66679 19.847 6.40575C20.585 7.14471 20.9991 8.14656 20.9984 9.1909C20.9977 10.2352 20.5822 11.2365 19.8432 11.9745L19.8423 11.9782Z" />
    </svg>
  );
}
