'use client';

import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeSearch } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { Icon, Link } from '@theme/components';
import { Image } from '@akinon/next/components/image';
import Results from './results';
import { ROUTES } from '@theme/routes';
import { useLocalization, useRouter } from '@akinon/next/hooks';
import SearchIcon from '@theme/components/icons/searchIcon';

export default function Search() {
  const { t } = useLocalization();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isSearchOpen = useAppSelector((state) => state.header.isSearchOpen);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchOpen) {
      inputRef.current?.focus();
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSearchOpen]);

  return (
    <>
      <div
        className={clsx(
          'absolute top-0 left-0 overflow-auto h-screen bg-white md:p-6 p-4 md:mt-0 mt-4 z-50 w-screen transition duration-500',
          isSearchOpen ? 'visible opacity-100' : 'invisible opacity-0'
        )}
      >
        <div className="relative flex items-center justify-center w-full md:mb-[4.5rem] mb-9">
          <Link
            href={ROUTES.HOME}
            passHref={true}
            className="self-center hidden w-[13.75rem] md:block"
            aria-label="Homepage"
          >
            <Image
              src="/logo-2.svg"
              alt="Zsa Zsa Zsu"
              aspectRatio={220 / 23}
              sizes="220"
              fill
              unoptimized
            />
          </Link>
          <div
            className="absolute top-0 right-0 flex items-center cursor-pointer max-md:left-0 gap-x-2"
            onClick={() => dispatch(closeSearch())}
          >
            <div className="flex items-center justify-center w-5 h-5 rounded-sm bg-plum-cheese bg-opacity-20 md:bg-transparent">
              <Icon name="close" size={14} className="text-plum-cheese" />
            </div>
            <span className="block text-sm text-black md:hidden font-kronaOne">
              {t('common.search.close')}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:gap-8 mx-auto">
          <div className="w-full max-w-[60.25rem] md:h-[3.875rem] h-10 border-b border-basalt-grey flex flex-col gap-2 self-center items-center md:flex-row">
            <div className="flex items-start w-full pb-4 md:pb-8">
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchText.trim() !== '') {
                    router.push(`${ROUTES.LIST}/?search_text=${searchText}`);
                  }
                }}
                className="w-full pl-2 text-2xl border-0 outline-none text-plum-cheese placeholder:text-base placeholder:md:text-2xl"
                placeholder={t('common.search.placeholder')}
                ref={inputRef}
              />
              <SearchIcon className="[&_path]:fill-cerebral-grey h-6 w-6 lg:h-[1.875rem] lg:w-[1.875rem]" />
            </div>
          </div>
          <Results searchText={searchText} />
        </div>
      </div>
    </>
  );
}
