import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/client-root.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/mobile-app-toggler.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/pz-providers.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-plus-jakarta-sans\"}],\"variableName\":\"plusJakartaSans\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"Fahkwang\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-fahkwang\",\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"fahkwang\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"Krona_One\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-kronaOne\",\"weight\":[\"400\"]}],\"variableName\":\"kronaOne\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"Taviraj\",\"arguments\":[{\"subsets\":[\"latin-ext\"],\"display\":\"swap\",\"variable\":\"--font-taviraj\",\"weight\":[\"400\",\"500\"]}],\"variableName\":\"taviraj\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./Kalnia-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-kalnia\"}],\"variableName\":\"kalnia\"}");
import(/* webpackMode: "eager" */ "/app/src/app/[commerce]/[locale]/[currency]/client-root.tsx");
import(/* webpackMode: "eager" */ "/app/src/assets/globals.scss");
import(/* webpackMode: "eager" */ "/app/src/components/canonical-url.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
import(/* webpackMode: "eager" */ "/app/src/views/footer-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/header/band.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/root-modal.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/footer-menu/footer-menu.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/footer-subscription/footer-subscription-form.tsx")