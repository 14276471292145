import { Link } from '@theme/components';

type FooterMenuItem = {
  value: { url: string; id: string; title: string };
  kwargs: { data_type: 'nested'; value: null };
};

export default function FooterMenuListItem({
  titlesId,
  subtitles,
}: {
  titlesId: string;
  subtitles: FooterMenuItem[];
}) {
  return (
    <ul className={
      `flex flex-col gap-3 lg:min-h-[18.75rem]`
    }>
      {subtitles
        .filter(
          (subtitleItem) => subtitleItem?.value.id === titlesId
        )
        .map((item) => (
          <li key={`footer-subtitleItem-${item.value.title}`}>
            <Link href={item.value.url} className='text-xs lg:text-base'>
              {item.value.title}
            </Link>
          </li>
        ))}
    </ul>
  );
}
