'use client';

import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  useGetBasketQuery,
  useUpdateQuantityMutation
} from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { BasketItem } from '@akinon/next/types';
import { register } from 'swiper/element/bundle';
import { useSession } from 'next-auth/react';

import { Price, Icon, LoaderSpinner, Link } from '@theme/components';
import { ROUTES } from '@theme/routes';
import { useLocalization } from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';

import useWindowResize from '@theme/hooks/use-window-resize';
import BasketRecommendationContent from '../widgets/basket-recommendation-content';
import { InsiderBasketObject } from '@theme/components/InsiderObject';
import Script from 'next/script';

interface MiniBasketItemProps {
  basketItem: BasketItem;
  highlightedItem: number;
  miniBasketListRef: MutableRefObject<HTMLUListElement>;
}
register();

function MiniBasketItem(props: MiniBasketItemProps) {
  const { basketItem, highlightedItem, miniBasketListRef } = props;
  const hasRetailrice =
    parseFloat(basketItem?.retail_price) > parseFloat(basketItem?.price);
  const hasBasketOffer = basketItem?.offer_badges[0]?.description;
  const dispatch = useAppDispatch();
  const [updateQuantityMutation] = useUpdateQuantityMutation();

  const { t } = useLocalization();

  const isHighlighted = useMemo(() => {
    return highlightedItem === basketItem?.product?.pk;
  }, [highlightedItem, basketItem?.product?.pk]);

  useEffect(() => {
    const miniBasketList = miniBasketListRef.current;

    if (highlightedItem === basketItem?.product?.pk) {
      if (miniBasketList?.scrollTop > 0) {
        miniBasketList.scrollTop = 0;
      }
    }
  }, [highlightedItem, basketItem?.product?.pk, miniBasketListRef]);

  return (
    <li
      style={{ order: isHighlighted ? '-1' : '0' }}
      className={clsx(
        'flex gap-3 border-b border-b-steam pb-3 mb-8 w-full max-w-[28.938rem]'
      )}
    >
      <Link
        href={basketItem?.product?.absolute_url}
        className={clsx('block shrink-0 transition-all duration-300')}
      >
        <Image
          src={basketItem?.product?.productimage_set?.[0]?.image ?? ''}
          alt={basketItem?.product?.name}
          width={111}
          height={111}
          imageClassName="w-[111px]"
          className="transition-all duration-300 w-20 h-20 lg:w-[6.938rem] lg:h-[6.938rem]"
        />
      </Link>
      <div className="flex flex-col">
        <div
          className={clsx(
            'flex items-center gap-1 transition-all duration-300',
            isHighlighted
              ? 'h-8 opacity-100 visible'
              : 'h-0 opacity-0 invisible'
          )}
        >
          <p className="text-[rgb(123,157,118)]">
            {t('basket.mini_basket.in_the_bag')}
          </p>
          <Icon name="check" className="text-[rgb(123, 157, 118)]" size={14} />
        </div>
        <Link
          href={basketItem?.product?.absolute_url}
          className="product-name block font-sans text-sm text-black mb-4 text-start leading-[1.063rem]"
          data-testid="mini-basket-item-name"
        >
          {basketItem?.product?.name}
        </Link>
        <div className="taxt-start flex gap-3">
          <span className="product-name font-sans text-xs text-squant mb-2 text-start">
            {t('common.common_product_attributes.quantity')}:
            {String.fromCharCode(32)}
            {basketItem?.quantity}
          </span>

          <span className="product-name font-sans text-xs text-squant mb-2 text-start">
            {t('common.common_product_attributes.color')}:
            {String.fromCharCode(32)}
            {basketItem?.product?.attributes?.ent_ColorDescription}
          </span>

          <span className="product-name font-sans text-xs text-squant">
            {t('basket.size')}:{String.fromCharCode(32)}
            {basketItem?.product?.attributes?.ent_ProductAtt24 === null ||
            basketItem?.product?.attributes?.ent_ProductAtt24 === '-'
              ? ' Standart'
              : basketItem?.product?.attributes?.ent_ProductAtt24}
          </span>
        </div>

        <footer className="flex flex-col mt-2">
          <div className="flex items-center mb-2">
            {hasBasketOffer ? (
              <>
                {(() => {
                  const firstMatchingBadge = basketItem?.offer_badges?.find(
                    (badge) => badge?.description !== 'Ücretsiz Kargo'
                  );
                  return firstMatchingBadge ? (
                    <p
                      key={firstMatchingBadge?.description}
                      className="text-sm font-sans bg-plum-cheese text-white px-[0.125rem] mr-2"
                    >
                      {firstMatchingBadge?.description}
                    </p>
                  ) : null;
                })()}
              </>
            ) : (
              hasRetailrice && (
                <>
                  <div className="flex flex-col items-end justify-center">
                    <span className="text-sm font-sans bg-plum-cheese text-white px-[0.125rem] mr-2">
                      -%
                      {Math.round(
                        100 -
                          (parseInt(basketItem?.price) /
                            parseInt(basketItem?.retail_price)) *
                            100
                      )}
                    </span>
                  </div>
                  <Price
                    value={parseFloat(basketItem?.retail_price)}
                    customStyle="text-squant text-sm line-through"
                  />
                </>
              )
            )}
            <div className="flex items-center">
              {hasBasketOffer &&
              basketItem?.retail_price !== basketItem?.price ? (
                <Price
                  customStyle="text-squant text-sm line-through"
                  value={basketItem?.retail_price}
                />
              ) : null}
            </div>
          </div>

          <div>
            <Price
              customStyle="text-black text-xl leading-[1.563rem]"
              value={basketItem?.price}
            />
          </div>
        </footer>
      </div>
    </li>
  );
}

type HomeProductRecommendationType = {
  title: {
    value: string;
  };
};

export default function MiniBasket() {
  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const dispatch = useAppDispatch();
  const { data: basket, isLoading, isSuccess } = useGetBasketQuery();

  const { t } = useLocalization();
  const { highlightedItem } = useAppSelector((state) => state.root.miniBasket);
  const [highlightedItemPk, setHighlightedItemPk] = useState(0);
  const [sortedBasket, setSortedBasket] = useState([]);
  const data = useGetWidgetQuery<HomeProductRecommendationType>(
    'basket-recommendation'
  );
  const { data: session, status } = useSession();

  const totalQuantity = useMemo(() => basket?.total_quantity ?? 0, [basket]);
  const miniBasketList = useRef();
  const windowSize = useWindowResize();
  const list = useAppSelector((state) => state.breadcrumb.list);

  useEffect(() => {
    if (highlightedItem > 0) {
      setHighlightedItemPk(highlightedItem);
    }
  }, [highlightedItem]);

  useEffect(() => {
    if (isSuccess) {
      if (highlightedItemPk > 0) {
        setSortedBasket(
          basket?.basketitem_set?.slice().sort((a, b) => {
            if (a?.product?.pk === highlightedItemPk) {
              return -1;
            } else if (b?.product?.pk === highlightedItemPk) {
              return 1;
            } else {
              return a?.product?.pk - b?.product?.pk;
            }
          })
        );
      } else {
        setSortedBasket(basket?.basketitem_set);
      }
    }
  }, [isSuccess, highlightedItem, basket, highlightedItemPk]);

  useEffect(() => {
    if (miniBasketOpen && windowSize.width >= 1024) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [miniBasketOpen]);

  return (
    <>
      <div
        className={clsx(
          miniBasketOpen && windowSize.width >= 1024
            ? 'opacity-100 visible'
            : 'opacity-0 invisible hidden',
          'fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-40 transition-all duration-300'
        )}
        onClick={() => {
          dispatch(closeMiniBasket());
        }}
      />
      <div
        className={clsx(
          miniBasketOpen && windowSize.width >= 1024
            ? 'flex flex-col opacity-100 visible'
            : 'opacity-0 invisible translate-y-full lg:translate-y-0',
          'fixed bottom-0 right-0 lg:h-screen h-5/6 lg:w-[45.188rem] w-full bg-white lg:px-8 px-3 z-[60] transition-all duration-300'
        )}
      >
        <header className="flex items-center w-full">
          <h3
            className={clsx(
              basket?.basketitem_set?.length > 0
                ? 'text-base lg:text-xl w-full font-bold text-incubi-darkness font-kronaOne lg:pb-4 pb-0 lg:mt-[4.688rem] mt-[4rem] mb-8 lg:pt-4 max-w-[28.938rem] mx-auto uppercase leading-6'
                : 'hidden'
            )}
          >
            {basket?.basketitem_set?.length > 0
              ? t('basket.mini_basket.my_bag')
              : ''}
          </h3>
          <Icon
            name="close"
            size={16}
            className="absolute top-8 right-8 ml-auto text-black-100 hover:cursor-pointer hover:fill-primary"
            onClick={() => dispatch(closeMiniBasket())}
          />
        </header>
        {isLoading && <LoaderSpinner />} {/* TODO: Fix spinner position */}
        {basket?.basketitem_set?.length > 0 ? (
          <div className="overflow-y-auto">
            {isSuccess && (
              <ul
                ref={miniBasketList}
                className="flex flex-col max-w-[28.938rem] mx-auto w-full text-start"
              >
                {sortedBasket?.map((basketItem) => (
                  <MiniBasketItem
                    key={basketItem?.product?.pk}
                    basketItem={basketItem}
                    highlightedItem={highlightedItem}
                    miniBasketListRef={miniBasketList}
                  />
                ))}
              </ul>
            )}
            <footer className="mini-basket__button flex flex-col gap-3 mt-4 lg:mt-8">
              <Link
                onClick={() => {
                  dispatch(closeMiniBasket());
                }}
                href={ROUTES.BASKET}
                data-testid="mini-basket-view-bag"
                className="w-full flex items-center uppercase rounded-[2rem] py-[0.875] px-6  justify-center bg-plum-cheese text-primary-foreground border lg:border-plum-cheese border-primary h-12 font-kronaOne text-base font-semibold transition-all hover:bg-primary-foreground hover:text-primary hover:text-plum-sheese mb-8 lg:mb-12 max-w-[28.938rem] mx-auto"
              >
                {t('basket.mini_basket.go_to_basket')}
              </Link>
            </footer>
            <div className="mini-basket-recommendation-products bg-black mb-10 max-w-[28.938rem] mx-auto lg:max-h-[23.188rem] max-h-[18.188rem]">
              <BasketRecommendationContent
                widgetData={data}
                sliderId="basketRecommendation"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col text-black items-center h-full text-center mt-[8.75rem] max-w-[28.688rem] mx-auto">
            <p className="font-bold font-kronaOne text-[2rem] mb-8 leading-10">
              {t('basket.empty.title')}
            </p>
            <p className="font-sans text-xl leading-[1.575rem] mb-8">
              {t('basket.empty.content_third')}
            </p>
            <Link
              onClick={() => {
                dispatch(closeMiniBasket());
              }}
              href={ROUTES.HOME}
              data-testid="mini-basket-view-bag"
              className="w-[20.813rem] flex items-center uppercase rounded-[2rem] py-[0.875] px-6  justify-center bg-plum-cheese text-primary-foreground border lg:border-plum-cheese border-primary h-12 font-kronaOne text-base font-semibold transition-all hover:bg-primary-foreground hover:text-primary hover:text-plum-sheese mb-9 lg:mb-12 mx-auto"
            >
              {t('basket.empty.button')}
            </Link>
          </div>
        )}
      </div>
      {/* Insider Script */}
      <InsiderBasketObject data={basket} breadcrumbList={list} />
      <Script id="insider-object-script-basket" strategy="afterInteractive">
        {`
          window.insider_object = window.insider_object || {};
          window.insider_object.page = {
            "type": "Basket"
          };

          if (Insider) {
            Insider?.eventManager?.dispatch('init-manager:re-initialize');
          }
        `}
      </Script>
      <Script
        id="insider-script"
        strategy="afterInteractive"
        async
        src="//zsazsazsu.api.useinsider.com/ins.js?id=10010479"
      ></Script>
      {/* Insider Script */}
    </>
  );
}
