'use client';

import ActionMenu from './action-menu';
import { Image } from '@akinon/next/components/image';
import { ROUTES } from '@theme/routes';

import { Link } from '@theme/components';
import { SearchButton } from '@theme/components/search-button';
import MobileHamburgerButton from './mobile-hamburger-button';
import MobileMenu from './mobile-menu';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import DesktopMenu from './desktop-menu';
import DesktopMenuButton from './desktop-menu-button';
import { useAppSelector } from '@akinon/next/redux/hooks';
import { RootState } from '@theme/redux/store';
import { CheckoutStep } from '@akinon/next/types';

export default function HeaderBand({ menu }) {
  const pathname = usePathname();
  const [isScrolled, setIsScrolled] = useState(false);
  const { steps } = useAppSelector((state: RootState) => state.checkout);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 62) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={clsx(
        'w-full',
        (pathname !== '/' && !isScrolled)
          ? 'bg-plum-cheese border-plum-cheese border lg:border-plum-cheese'
          : isScrolled
          ? 'bg-white border lg:border-plum-cheese'
          : ''
      )}
    >
      <div
        className={
          clsx(
            pathname === '/'
              ? `-mb-[3.875rem] ${
                  isScrolled
                    ? 'fixed z-10 left-0 right-0 border-b border-plum-cheese bg-white top-0'
                    : 'absolute top-0 z-30 bg-navbar-gradient'
                }`
              : 'bg-white',
            {
              '!bg-plum-cheese ': pathname !== '/'
            },

            {
              '!justify-center': steps.current === CheckoutStep.Confirmation
            },
            {
              'container max-w-[76.625rem] !px-0':
                pathname === '/orders/checkout'
            }
          ) +
          ' flex justify-between lg:items-center items-start w-full px-3 py-4 lg:px-12 lg:pt-5 lg:pb-4 max-lg:mb-0'
        }
      >
        <div
          className={clsx('flex lg:gap-x-8 gap-x-6', {
            hidden:
              pathname === '/orders/checkout' ||
              steps.current === CheckoutStep.Confirmation
          })}
        >
          <MobileHamburgerButton isMobile={true} isScrolled={isScrolled} />
          <DesktopMenuButton isMobile={false} isScrolled={isScrolled} />
          <DesktopMenu menu={menu} />
          <MobileMenu isMobile={true} menu={menu} />
          <SearchButton isScrolled={isScrolled} />
        </div>
        <div className="h-auto">
          <div className="flex items-center justify-center h-full text-center overflow-ellipsis line-clamp-2">
            <Link
              href={ROUTES.HOME}
              passHref={true}
              className={clsx('self-center hidden w-[13.75rem] lg:block', {
                'ml-4': pathname === '/orders/checkout'
              })}
              aria-label="Homepage"
            >
              <Image
                src={
                  !isScrolled
                    ? '/logo.svg'
                    : '/logo-2.svg'
                }
                alt="Zsa Zsa Zsu"
                aspectRatio={220 / 23}
                sizes="220"
                fill
                unoptimized
              />
            </Link>
            <Link
              href={ROUTES.HOME}
              passHref={true}
              className={clsx(
                'self-center block lg:hidden sm:justify-self-center sm:p-0 w-16',
                {
                  'w-[13.75rem] ml-4': pathname === '/orders/checkout'
                }
              )}
              aria-label="Homepage"
            >
              {pathname === '/orders/checkout' ? (
                <Image
                  src={
                    !isScrolled
                      ? '/logo.svg'
                      : '/logo-2.svg'
                  }
                  alt="Zsa Zsa Zsu"
                  aspectRatio={220 / 23}
                  sizes="220"
                  fill
                />
              ) : (
                <Image
                  src={
                    !isScrolled
                      ? '/mobile-logo.svg'
                      : '/mobile-logo-2.svg'
                  }
                  alt="Zsa Zsa Zsu"
                  aspectRatio={1}
                  sizes="64"
                  fill
                  unoptimized
                />
              )}
            </Link>
          </div>
        </div>

        <div
          className={clsx('h-full', {
            hidden: steps.current === CheckoutStep.Confirmation
          })}
        >
          <div className="flex items-center justify-end h-full">
            <ActionMenu isScrolled={isScrolled} />
          </div>
        </div>
      </div>
    </div>
  );
}
