import { ToastContainer } from 'react-toastify';
import { Icon } from './icon';

interface IProps {
  containerId: string;
}

export const CustomToastContainer = (props: IProps) => {
  return (
    <ToastContainer
      containerId={props.containerId}
      toastClassName={() =>
        'rounded-none flex gap-2 items-center justify-between p-4 my-2 min-h-10 cursor-pointer bg-[#F8EEE5] [&_svg]:fill-black'
      }
      bodyClassName={() =>
        'flex gap-4 text-black text-sm font-white block font-kronaOne'
      }
      style={{ minWidth: '22.5rem' }}
      limit={10}
      autoClose={8000}
      closeButton={({ closeToast }) => (
        <Icon
          className="text-black"
          name="close"
          size={20}
          onClick={closeToast}
        />
      )}
    />
  );
};
