'use client';

import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { Link } from '@theme/components';

interface IItem {
  value: {
    color: string;
    link: string;
    title: string;
  };
  kwargs: { data_type: string; value: null };
}

const MenuStatics = () => {
  const { data } = useGetWidgetQuery('menu-statics');

  return (
    <ul className="flex flex-col gap-6 pt-8">
      {data?.attributes?.menu_statics &&
        data?.attributes?.menu_statics.map((item: IItem, index: number) => {
          return (
            <li
              key={index}
              style={
                {
                  '--text-color': item?.value?.color || '#000000'
                } as React.CSSProperties
              }
            >
              <Link
                href={item?.value?.link}
                className="text-[var(--text-color)]"
              >
                {item?.value?.title}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

export default MenuStatics;
