'use client';

import { twMerge } from 'tailwind-merge';
import useWindowResize from '@theme/hooks/use-window-resize';
import ImageItem from './image-item';
import { useEffect, useState } from 'react';
import { MenuItemType } from '@akinon/next/types';

type IMenuLevel =
  | MenuItemType
  | null
  | { url: string; label: 'all'; children: null };

interface IList {
  value?: {
    url?: string;
    image?: string;
    title?: string;
  };
  kwargs: {
    data_type?: string;
    value?: {
      image?: {
        url?: string;
        data_type?: string;
        value?: string;
      };
    };
  };
}

const MenuImages = ({
  className,
  imageClassName,
  selectedMenu,
  list
}: {
  className?: string;
  selectedMenu?: {
    firstLevel: MenuItemType | null;
    secondLevel: IMenuLevel;
  };
  imageClassName?: string;
  list: IList[];
}) => {
  const { width } = useWindowResize();
  const [formattedList, setFormattedList] = useState(list);

  const formatList = (
    width: number,
    selectedMenu: {
      firstLevel: MenuItemType | null;
      secondLevel: IMenuLevel;
    },
    list: IList[]
  ) => {
    if (width >= 1440) {
      return selectedMenu?.secondLevel ? list.slice(-2) : list.slice(-3);
    } else if (width >= 1024) {
      return selectedMenu?.secondLevel ? list.slice(-1) : list.slice(-2);
    }
    return [];
  };

  useEffect(() => {
    if (selectedMenu) {
      setFormattedList(formatList(width, selectedMenu, list));
    }
  }, [width, selectedMenu, list]);

  return (
    <div className={twMerge('flex gap-4 2xl:gap-8', className)}>
      {(selectedMenu && formattedList ? formattedList : list).map((item) => {
        return (
          <ImageItem
            key={`menu-image-${item?.value?.title}-${item?.kwargs?.value?.image?.url}`}
            item={item}
            imageClassName={imageClassName}
          />
        );
      })}
    </div>
  );
};

export default MenuImages;
