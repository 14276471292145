import { createSlice } from '@reduxjs/toolkit';

export interface InitialState {
  list: { url: string, text: string }[];
}

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: {
    list: []
  } as unknown as InitialState,
  reducers: {
    updateBreadcrumbList(state, action) {
      state.list = action.payload;
    }
  }
});

export const { updateBreadcrumbList } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
