'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import { toggleMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';

import { PwaBackButton } from './pwa-back-button';
import { Button } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import EqualIcon from '@theme/components/icons/equalIcon';

interface DesktopMenuButtonProps {
  isScrolled: boolean;
  isMobile: boolean;
}

export default function DesktopMenuButton(props: DesktopMenuButtonProps) {
  const { isScrolled, isMobile } = props;
  const dispatch = useAppDispatch();
  const { t } = useLocalization();
  return (
    <div className={!isMobile && "lg:flex hidden"}>
      <PwaBackButton />
      <Button
        className={clsx([
          'w-fit',
          'h-6',
          'bg-transparent',
          'flex',
          'items-center',
          'justify-center',
          'px-0',
          'border-none',
          'hover:bg-transparent',
          'font-kronaOne',
          'gap-2',
          'p-2'
        ])}
        onClick={() => {
          dispatch(toggleMobileMenu());
        }}
      >
        <EqualIcon
          className={
            !isScrolled
              ? 'fill-white [&_path]:fill-white'
              : 'fill-plum-cheese [&_path]:plum-cheese'
          }
        />
        <span
          className={clsx(
            !isScrolled
              ? 'text-white hover:text-white hidden lg:block'
              : 'text-black hover:text-black hidden lg:block'
          )}
        >
          {t('common.navbar.menu')}
        </span>
      </Button>
    </div>
  );
}
