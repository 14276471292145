'use client';

import React, { useEffect } from 'react';
import { ProductItem } from '@theme/views/product-item';
import styles from './styles.module.css';

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { Image } from '@akinon/next/components/image';
import { twMerge } from 'tailwind-merge';
import { Icon } from '@theme/components';
import useWindowResize from '@theme/hooks/use-window-resize';
import ArrowLeftIcon from '@theme/components/icons/arrowLeft';
import ArrowRightIcon from '@theme/components/icons/arrowRight';

// register Swiper custom elements
register();

export default function BasketRecommendationContent({
  widgetData,
  sliderId,
  classes = ''
}) {
  const data = widgetData?.data;
  const products = data?.products;
  const { width } = useWindowResize();
  const sliderRef = React.useRef(null);

  useEffect(() => {
    const swiperElement = sliderRef.current;

    if (!swiperElement || !widgetData) {
      return;
    }

    Object.assign(swiperElement, {
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        1024: {
          slidesPerView: 2.25,
          spaceBetween: 32
        }
      }
    });

    (swiperElement as any).initialize();
  }, [widgetData, sliderRef]);

  const renderSlides = () => {
    return products?.map((product, index) => (
      <swiper-slide
        key={index}
        style={{
          height: 'auto'
        }}
        className="basket-recommendation__swiper"
        class={twMerge(styles.swiper)}
      >
        <ProductItem
          product={product}
          index={index}
          width={512}
          height={512}
          textColor={data?.attributes?.product_item_text_color?.value}
          favouriteIconColor={data?.attributes?.favourite_icon_color?.value}
          favouriteIconColorActive={
            data?.attributes?.favourite_icon_color_active?.value
          }
          favouriteIconColorHover={
            data?.attributes?.favourite_icon_color_hover?.value
          }
          className="lg:hover:border-none lg:hover:p-0 lg:p-0 lg:w-[10.625rem] w-[7.625rem]"
        />
      </swiper-slide>
    ));
  };

  return (
    <div
      className={twMerge(
        `flex items-center justify-center w-full [&_#${sliderId}]:w-full [&_#${sliderId}]:h-full`,
        classes
      )}
      style={{ backgroundColor: data?.attributes?.background_color?.value }}
    >
      <p> {data?.attributes.ent_Barcode} </p>
      <div className="w-full max-w-[1432px] relative">
        {data?.attributes?.title?.value && (
          <header className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-y-4 mb-[1.125rem] font-kronaOne">
            <h2 className="flex gap-x-3 items-center">
              <span
                className="text-xl"
                style={{ color: data?.attributes?.title_color?.value }}
              >
                {data?.attributes?.title?.value}
              </span>
              {data?.attributes?.icon?.kwargs?.url && (
                <Image
                  src={data?.attributes?.icon?.kwargs?.url}
                  alt="icon"
                  width={30}
                  height={30}
                />
              )}
            </h2>
            {data?.attributes?.subtitle?.value && (
              <h3
                className="text-xs uppercase underline underline-offset-2"
                style={{ color: data?.attributes?.subtitle_color?.value }}
              >
                <a
                  href={data?.attributes?.url?.value}
                  style={{ color: data?.attributes?.subtitle_color?.value }}
                >
                  {data?.attributes?.subtitle?.value}
                </a>
              </h3>
            )}
          </header>
        )}
        <swiper-container
          navigation="true"
          navigation-next-el={'#custom-nav-button--next-' + sliderId}
          navigation-prev-el={'#custom-nav-button--prev-' + sliderId}
          init={false}
          ref={sliderRef}
        >
          {products && renderSlides()}
        </swiper-container>
        <button
          id={'custom-nav-button--prev-' + sliderId}
          className="absolute z-10 top-1/2 4xl:-left-[4.1875rem] max-4xl:hidden disabled:opacity-30"
        >
          <ArrowLeftIcon
            style={{ fill: `${data?.attributes?.title_color?.value}` }}
            className={twMerge('w-8 h-8')}
          />
        </button>
        <button
          id={'custom-nav-button--next-' + sliderId}
          className="absolute z-10 top-1/2 4xl:-right-[4.1875rem] max-4xl:hidden disabled:opacity-30"
        >
          <ArrowRightIcon
            style={{ fill: `${data?.attributes?.title_color?.value}` }}
            className={twMerge('w-8 h-8')}
          />
        </button>
      </div>
    </div>
  );
}
