export default function UserIcon({ className }: { className: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M18 9C18 10.1867 17.6481 11.3467 16.9888 12.3334C16.3295 13.3201 15.3925 14.0892 14.2961 14.5433C13.1997 14.9974 11.9933 15.1162 10.8295 14.8847C9.66557 14.6532 8.59648 14.0818 7.75736 13.2426C6.91825 12.4035 6.3468 11.3344 6.11529 10.1705C5.88378 9.00666 6.0026 7.80026 6.45673 6.7039C6.91085 5.60754 7.67989 4.67047 8.66658 4.01118C9.65328 3.35189 10.8133 3 12 3C13.5913 3 15.1174 3.63214 16.2426 4.75736C17.3679 5.88258 18 7.4087 18 9Z"
      />
      <path d="M21.6486 19.8756C20.2208 17.4072 18.0205 15.6372 15.4527 14.7981C16.7229 14.042 17.7097 12.8898 18.2617 11.5185C18.8136 10.1473 18.9002 8.63272 18.5081 7.20749C18.116 5.78226 17.2668 4.52515 16.0911 3.62921C14.9154 2.73327 13.4781 2.24805 11.9999 2.24805C10.5217 2.24805 9.08438 2.73327 7.90866 3.62921C6.73294 4.52515 5.88382 5.78226 5.49171 7.20749C5.09959 8.63272 5.18616 10.1473 5.73813 11.5185C6.29009 12.8898 7.27692 14.042 8.54708 14.7981C5.97926 15.6362 3.77895 17.4062 2.35114 19.8756C2.29878 19.961 2.26405 20.056 2.249 20.155C2.23394 20.254 2.23888 20.355 2.2635 20.4521C2.28813 20.5492 2.33195 20.6404 2.39238 20.7202C2.45281 20.8001 2.52862 20.867 2.61535 20.9171C2.70207 20.9672 2.79795 20.9995 2.89733 21.0119C2.99671 21.0243 3.09757 21.0167 3.19397 20.9896C3.29037 20.9624 3.38034 20.9162 3.45859 20.8537C3.53684 20.7912 3.60178 20.7136 3.64958 20.6256C5.41583 17.5731 8.5377 15.7506 11.9999 15.7506C15.4621 15.7506 18.584 17.5731 20.3502 20.6256C20.398 20.7136 20.4629 20.7912 20.5412 20.8537C20.6194 20.9162 20.7094 20.9624 20.8058 20.9896C20.9022 21.0167 21.0031 21.0243 21.1024 21.0119C21.2018 20.9995 21.2977 20.9672 21.3844 20.9171C21.4712 20.867 21.547 20.8001 21.6074 20.7202C21.6678 20.6404 21.7116 20.5492 21.7363 20.4521C21.7609 20.355 21.7658 20.254 21.7508 20.155C21.7357 20.056 21.701 19.961 21.6486 19.8756ZM6.74989 9.0006C6.74989 7.96225 7.05779 6.94721 7.63467 6.08385C8.21155 5.2205 9.03149 4.54759 9.9908 4.15023C10.9501 3.75287 12.0057 3.6489 13.0241 3.85147C14.0425 4.05405 14.978 4.55406 15.7122 5.28829C16.4464 6.02251 16.9464 6.95797 17.149 7.97637C17.3516 8.99477 17.2476 10.0504 16.8503 11.0097C16.4529 11.969 15.78 12.7889 14.9166 13.3658C14.0533 13.9427 13.0382 14.2506 11.9999 14.2506C10.608 14.2491 9.27347 13.6955 8.28922 12.7113C7.30498 11.727 6.75138 10.3925 6.74989 9.0006Z" />
    </svg>
  );
}
