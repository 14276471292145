import { Image } from '@akinon/next/components/image';
import { Link } from '../link';
import { twMerge } from 'tailwind-merge';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';

interface IItem {
  value?: {
    url?: string;
    image?: string;
    title?: string;
  };
  kwargs: {
    data_type?: string;
    value?: {
      image?: {
        url?: string;
        data_type?: string;
        value?: string;
      };
    };
  };
}
const ImageItem = ({
  item,
  imageClassName,
}: {
  item: IItem;
  imageClassName: string;
 }) => {
  const { title, url } = item.value;
  const dispatch = useAppDispatch();

  return (
    <>
      {url ? (
        <Link className="flex flex-col shrink-0" href={url}>
          <Image
            onClick={() => dispatch(closeMobileMenu())}
            imageClassName={twMerge(imageClassName)}
            alt={title ?? ''}
            width={232}
            height={366}
            src={item?.kwargs?.value?.image?.url ?? ''}
          />

          {title && (
            <figcaption className="font-medium mt-3">{title ?? ''}</figcaption>
          )}
        </Link>
      ) : (
        <>
          <Image
            imageClassName={twMerge(imageClassName)}
            alt={title ?? 'Zsa Zsa Zsu'}
            width={232}
            height={366}
            src={item?.kwargs?.value?.image?.url ?? ''}
          />
          {title && (
            <figcaption className="font-medium mt-3">{title}</figcaption>
          )}
        </>
      )}
    </>
  );
};

export default ImageItem;
