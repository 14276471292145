import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IinitialState {
  isSorterOpen: boolean;
  isFilterMenuOpen: boolean;
}

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    isSorterOpen: false,
    isFilterMenuOpen: false
  } as IinitialState,
  reducers: {
    toggleFilterMenu(state) {
      state.isFilterMenuOpen = !state.isFilterMenuOpen;
    },
    closeFilterMenu(state) {
      state.isFilterMenuOpen = false;
    },
    openFilterMenu(state) {
      state.isFilterMenuOpen = true;
    },
    openSorter(state) {
      state.isSorterOpen = true;
    },
    closeSorter(state) {
      state.isSorterOpen = false;
    },
    toggleSorter(state) {
      state.isSorterOpen = !state.isSorterOpen;
    }
  }
});

export const { openSorter, closeSorter, toggleSorter, toggleFilterMenu, openFilterMenu, closeFilterMenu } =
  categorySlice.actions;
export default categorySlice.reducer;
