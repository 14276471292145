'use client';

import { useLocalization } from '@akinon/next/hooks';
import { openSearch } from '@akinon/next/redux/reducers/header';
import Search from '../views/header/search';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import SearchIcon from './icons/searchIcon';
import { useEffect } from 'react';
import useWindowResize from '@theme/hooks/use-window-resize';

interface SearchButtonProps {
  isScrolled: boolean;
}

export const SearchButton = (props: SearchButtonProps) => {
  const { isScrolled } = props;
  const { t } = useLocalization();
  const dispatch = useAppDispatch();
  const { isSearchOpen } = useAppSelector((state) => state.header);
  const { width } = useWindowResize();

  useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isSearchOpen, width]);

  return (
    <>
      <button
        onClick={() => dispatch(openSearch())}
        className="flex items-center gap-2 text-sm text-white cursor-pointer font-kronaOne"
        data-testid="header-nav-search"
      >
        <SearchIcon
          className={clsx(
            !isScrolled
              ? 'fill-white [&_path]:fill-white'
              : 'fill-plum-cheese [&_path]:plum-cheese'
         ) }
        />

        <span
          className={clsx(
            !isScrolled
              ? 'text-white hover:text-white hidden lg:block'
              : 'text-black hover:text-black hidden lg:block'
          )}
        >
          {t('common.navbar.search')}
        </span>
      </button>
      {isSearchOpen && <Search />}
    </>
  );
};
