'use client';

import React, { useRef, useState } from 'react';
import { MenuItemType } from '@akinon/next/types';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import { Icon, Link, LanguageSelect } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import MenuStatics from '@theme/widgets/menu-statics';
import MobilSubmenu from '@theme/components/menu/mobil-submenu';

interface MobileMenuProps {
  menu: MenuItemType[];
  isMobile: boolean;
}

export default function MobileMenu(props: MobileMenuProps) {
  const { menu, isMobile } = props;
  const dispatch = useAppDispatch();
  const secondLevelMenuRef = useRef<HTMLDivElement>(null);
  const [selectedMenu, setSelectedMenu] = useState({
    secondLevel: null,
    thirdLevel: null
  });

  const { t } = useLocalization();

  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );

  const selectedListIndex = menu.findIndex(
    (item) => item?.label === selectedMenu?.secondLevel?.label
  );
  const imageList =
    menu[selectedListIndex]?.extra_context?.attributes?.images ?? [];

  const { data: session } = useSession();
  const email = session?.user?.email?.toLowerCase();
  const languageSelectAllowedEmails = ['ecommerc.io', 'zsazsazsu.com.tr'];

  // Check if any of the allowed emails are included in the session email (partial match allowed like ecommerc.io by some())
  const showLanguageSelect = languageSelectAllowedEmails.some((allowedEmail) =>
    email?.includes(allowedEmail.toLowerCase())
  );

  React.useEffect(() => {
    setSelectedMenu({ secondLevel: null, thirdLevel: null });
  }, [isMobileMenuOpen]);

  return (
    <>
      {/* MENU OVERLAY */}
      <div
        className={clsx(
          'fixed top-0 left-0 w-screen h-screen invisible opacity-0 bg-black bg-opacity-80 transition duration-500',
          {
            '!visible !opacity-100 scroll-lock z-30': isMobileMenuOpen
          },
          {
            '-z-50': !isMobileMenuOpen
          },
          {
            'lg:hidden block': isMobile
          }
        )}
        // TODO: Remove this after we have a better solution for clicking outside of the menu
        onClick={() => {
          dispatch(closeMobileMenu());
          setSelectedMenu({ secondLevel: null, thirdLevel: null });
        }}
      />

      {/* TODO: Add a way to close the menu when clicking outside of it */}
      <div
        className={clsx(
          'fixed top-0 left-0 flex flex-col bg-white w-full h-screen invisible opacity-0 transition duration-500 transform -translate-x-72',
          {
            '!visible !opacity-100 translate-x-0 z-[60]': isMobileMenuOpen
          },
          {
            '-z-50': !isMobileMenuOpen
          },
          {
            'lg:hidden block': isMobile
          }
        )}
      >
        <div className="relative flex-1 pt-3 pl-5 overflow-x-hidden">
          <div
            className="flex items-center pt-3 cursor-pointer max-lg:left-0 gap-x-2"
            onClick={() => dispatch(closeMobileMenu())}
          >
            <div className="flex items-center justify-center w-5 h-5 rounded-sm bg-plum-cheese bg-opacity-20 lg:bg-transparent">
              <Icon name="close" size={14} className="text-plum-cheese" />
            </div>
            <span className="block text-sm text-black lg:hidden font-kronaOne">
              {t('common.search.close')}
            </span>
          </div>

          {/* First Level Menu*/}
          <ul
            className={clsx('pl-3 pt-12 transition duration-500 transform', {
              '-translate-x-full':
                selectedMenu.secondLevel || selectedMenu.thirdLevel
            })}
          >
            {menu.map((item) => {
              return (
                item.label != null && (
                  <li
                    key={`mobilMenu-${item.label}-${item.pk}`}
                    className="mb-6 text-2xl text-black font-kronaOne"
                    style={
                      {
                        '--text-color':
                          item?.extra_context?.attributes.css_class?.value ||
                          '#000000'
                      } as React.CSSProperties
                    }
                  >
                    <Link
                      href={item.url}
                      onClick={(e) => {
                        if (item.children.length > 0) {
                          e.preventDefault();
                          setSelectedMenu({
                            secondLevel: item,
                            thirdLevel: null
                          });
                        }
                        if (item.children.length === 0) {
                          dispatch(closeMobileMenu());
                        }
                      }}
                      className="flex items-center justify-between text-[var(--text-color)]"
                    >
                      <span>{item.label}</span>
                    </Link>
                  </li>
                )
              );
            })}

            {/* Statics */}
            <li className="mb-6 mt-4 text-black border-t border-cerebral-grey">
              <MenuStatics />
            </li>
            <li>
              {showLanguageSelect ? (
                <div className="lg:hidden">
                  <LanguageSelect />
                </div>
              ) : null}
            </li>
          </ul>

          {/* Second Level Menu*/}
          <MobilSubmenu
            submenuRef={secondLevelMenuRef}
            menu={selectedMenu?.secondLevel}
            setSelectedMenu={setSelectedMenu}
            imageList={imageList}
            isSecondLevel
          />

          {/* Third Level Menu*/}
          <MobilSubmenu
            minHeight={secondLevelMenuRef?.current?.scrollHeight}
            menu={selectedMenu?.thirdLevel}
            setSelectedMenu={setSelectedMenu}
            imageList={imageList}
          />
        </div>
      </div>
    </>
  );
}
