import { ProductItem } from '@theme/views/product-item';

export const PopularProducts = ({ data, title }) => {
  return (
    <div>
      <h2 className="text-sm mb-6">{title}</h2>
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-x-4 gap-y-8">
        {data?.map((product, index) => (
          <ProductItem
            key={product.pk}
            index={index}
            product={product}
            width={384}
            height={384}
            className='!border-none !p-0'
          />
        ))}
      </div>
    </div>
  );
};
