'use client';

import { usePathname } from 'next/navigation';

export default function FooterWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();

  if (pathname === '/en/orders/checkout' || pathname === '/orders/checkout')
    return null;

  return <>{children}</>;
}
