'use client';

import { useState } from 'react';
import { MenuItemType } from '@akinon/next/types';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import clsx from 'clsx';
import { Image } from '@akinon/next/components/image';

import { Icon, Link } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import { SearchButton } from '@theme/components/search-button';
import { ROUTES } from '@theme/routes';
import ActionMenu from './action-menu';
import MenuImages from '@theme/components/menu/menu-images';

type IMenuLevel =
  | MenuItemType
  | null
  | { url: string; label: 'all'; children: null };

export default function DesktopMenu({ menu }: { menu: MenuItemType[] }) {
  const dispatch = useAppDispatch();
  const [selectedMenu, setSelectedMenu] = useState<{
    firstLevel: MenuItemType | null;
    secondLevel: IMenuLevel;
  }>({
    firstLevel: null,
    secondLevel: null
  });
  const { t } = useLocalization();

  const selectedListIndex = menu.findIndex(
    (item) => item?.label === selectedMenu.firstLevel?.label
  );
  const imageList =
    menu[selectedListIndex]?.extra_context?.attributes?.images ?? [];

  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );

  return (
    <div
      className={clsx(
        'hidden fixed top-0 left-0 flex-col bg-white w-full h-screen invisible opacity-0 transform duration-700 z-50 lg:flex',
        {
          'transition-all invisible opacity-0 -translate-x-72':
            !isMobileMenuOpen,
          '!visible !opacity-100 translate-x-0': isMobileMenuOpen
        }
      )}
    >
      <div className="relative flex-1 overflow-x-hidden">
        <div
          className="h-[4.875rem] justify-between px-12 flex items-center cursor-pointer max-lg:left-0 border-b-[0.0313rem] border-naturale border-opacity-30"
          onClick={() => dispatch(closeMobileMenu())}
        >
          <div className="flex gap-x-[1.875rem]">
            <div className="flex gap-x-2">
              <div className="flex items-center justify-center w-5 h-5 rounded-sm bg-plum-cheese bg-opacity-20">
                <Icon name="close" size={14} className="text-plum-cheese" />
              </div>
              <span className="text-sm text-black font-kronaOne">
                {t('common.search.close')}
              </span>
            </div>
            <SearchButton isScrolled={true} />
          </div>
          <Link
            href={ROUTES.HOME}
            passHref={true}
            className="self-center hidden w-[13.75rem] lg:block"
            aria-label="Homepage"
          >
            <Image
              src="/logo-2.svg"
              alt="Zsa Zsa Zsu"
              aspectRatio={220 / 23}
              sizes="220"
              fill
              unoptimized
            />
          </Link>
          <ActionMenu isScrolled={true} />
        </div>
        <div className="flex">
          {/* Main Menu */}
          <ul className="pl-12 pt-12 transition duration-500 transform shrink-0">
            {menu?.map((item) => {
              return (
                item.label != null && (
                  <li
                    key={item?.pk}
                    style={{
                      color:
                        item?.extra_context?.attributes.css_class?.value ?? ''
                    }}
                    className={clsx(
                      `flex items-center mb-6 text-2xl text-black gap-x-4 font-kronaOne transition-all`,
                      {
                        'opacity-30':
                          item.label !== selectedMenu?.firstLevel?.label &&
                          selectedMenu?.firstLevel
                      }
                    )}
                  >
                    <Link
                      href={item?.url}
                      onClick={(e) => {
                        setSelectedMenu({
                          firstLevel: item,
                          secondLevel: null
                        });

                        if (item?.children?.length > 0) {
                          e.preventDefault();
                        }
                        if (item?.children?.length === 0) {
                          dispatch(closeMobileMenu());
                        }
                      }}
                      className="flex items-center justify-between"
                    >
                      <span>{item.label}</span>
                    </Link>

                    {/* Separator Line */}
                    {selectedMenu?.firstLevel?.children?.length > 0 && (
                      <div
                        className={`h-[0.0313rem] bg-cerebral-grey min-w-[2.25rem] 3xl:min-w-[7.5rem] flex-grow ${
                          selectedMenu?.firstLevel?.label === item.label
                            ? 'opacity-100'
                            : 'opacity-0'
                        } `}
                      ></div>
                    )}
                  </li>
                )
              );
            })}
          </ul>

          {/* Second Level Submenu */}
          {/* Second Level Submenu Gets Info from Children of firstLevel Menu*/}
          {selectedMenu?.firstLevel?.children?.length > 0 && (
            <div
              className={clsx(
                'bg-white invisible opacity-0 transition duration-500 transform translate-x-full pointer-events-auto shrink-0 min-w-[11.9375rem]',
                {
                  '!visible !opacity-100 !translate-x-0':
                    selectedMenu?.firstLevel
                },
                { '!pointer-events-none': !isMobileMenuOpen }
              )}
            >
              <div className="flex flex-col w-full pt-12 pl-4 gap-4">
                {/* All Link */}
                <div
                  key={selectedMenu?.firstLevel?.url}
                  className="flex flex-col"
                >
                  <Link
                    onClick={() => {
                      dispatch(closeMobileMenu());
                      setSelectedMenu((prev) => {
                        return {
                          ...prev,
                          secondLevel: {
                            url: selectedMenu?.firstLevel?.url,
                            label: 'all',
                            children: null
                          }
                        };
                      });
                    }}
                    href={selectedMenu?.firstLevel?.url}
                    className={
                      'font-sans text-base font-medium text-primary-400'
                    }
                  >
                    {t('common.all')}
                  </Link>
                </div>

                {selectedMenu?.firstLevel
                  ? selectedMenu?.firstLevel?.children.map((child) => (
                      <div
                        key={child.pk}
                        className={'flex items-center gap-x-4 transition-all'}
                      >
                        <Link
                          onClick={(e) => {
                            if (child?.children?.length > 0) {
                              e.preventDefault();
                              setSelectedMenu((prev) => {
                                return { ...prev, secondLevel: child };
                              });
                            }
                            if (child?.children?.length === 0) {
                              setSelectedMenu((prev) => {
                                return {
                                  ...prev,
                                  secondLevel: child
                                };
                              });
                              dispatch(closeMobileMenu());
                            }
                          }}
                          href={child.url}
                          className="font-sans text-base font-medium text-primary-400"
                        >
                          {child.label}
                        </Link>

                        {/* Separator Line */}
                        {selectedMenu?.secondLevel?.children?.length > 0 && (
                          <div
                            className={`flex-grow h-[0.0313rem] bg-cerebral-grey min-w-[2.25rem] 3xl:min-w-[7.5rem] ${
                              selectedMenu.secondLevel.label === child.label
                                ? 'opacity-100'
                                : 'opacity-0'
                            } `}
                          ></div>
                        )}
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}

          {/* Third Level Submenu */}
          {/* Third Level Submenu Gets Info from Children of SecondLevel Menu*/}
          {selectedMenu?.secondLevel?.children?.length > 0 && (
            <div
              className={clsx(
                'relative left-0 bg-white invisible opacity-0 transition duration-500 transform translate-x-full pointer-events-auto shrink-0 min-w-[11.9375rem]',
                {
                  '!visible !opacity-100 !translate-x-0':
                    selectedMenu?.secondLevel
                },
                { '!pointer-events-none': !isMobileMenuOpen }
              )}
            >
              <div className="flex flex-col w-full pt-12 pl-4 absolute left-0">
                {/* The Link Named All */}
                <div
                  key={selectedMenu?.secondLevel?.url}
                  className="flex flex-col"
                >
                  <Link
                    onClick={() => dispatch(closeMobileMenu())}
                    href={selectedMenu?.secondLevel?.url}
                    className="pb-4 font-sans text-base font-medium text-primary-400"
                  >
                    {t('common.all')}
                  </Link>
                </div>

                {selectedMenu?.secondLevel
                  ? selectedMenu?.secondLevel?.children.map((child) => (
                      <div key={child.pk} className="flex flex-col">
                        <Link
                          onClick={() => dispatch(closeMobileMenu())}
                          href={child.url}
                          className="pb-4 font-sans text-base font-medium text-primary-400"
                        >
                          {child.label}
                        </Link>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}

          {selectedMenu?.firstLevel && (
            <MenuImages
              selectedMenu={selectedMenu}
              className="pt-12 ml-4 2.5xl:ml-[3.75rem] 3xl:ml-[7.5rem]"
              imageClassName="w-[14.5rem] h-[22.875rem]"
              list={imageList}
            />
          )}
        </div>
      </div>
    </div>
  );
}
