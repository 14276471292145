'use client';

import { useEffect } from 'react';

export function InsiderProductObject({ data, breadcrumbList }) {
  const product = data?.product;

  useEffect(() => {
    const taxonomyList = breadcrumbList?.map((breadcrumb) => breadcrumb?.label);
    (window as any).insider_object = (window as any).insider_object || {};
    (window as any).insider_object.product = {
      id: product?.pk.toString(),
      name: product?.name,
      taxonomy: taxonomyList,
      currency: product?.currency_type?.toUpperCase(),
      unit_price: parseFloat(product?.price),
      unit_sale_price: parseFloat(product?.retail_price),
      url: `${window.location.origin}${product?.absolute_url}`,
      stock: product?.stock,
      color: product?.attributes?.ent_ColorDescription,
      size: product?.attributes?.ent_ColorDescription,
      product_image_url: product?.productimage_set?.[0]?.image,
      custom: {
        season: product?.attributes?.ent_ProductAtt02
      }
    };
  });

  return null;
}

export function InsiderListingObject({
  data,
  breadcrumbList
}: {
  data: any;
  breadcrumbList: any;
}) {
  const products = data?.products;

  useEffect(() => {
    const taxonomyList = breadcrumbList?.map((breadcrumb) => breadcrumb?.text);
    (window as any).insider_object = (window as any).insider_object || {};
    (window as any).insider_object.listing = {
      items: products?.map((item) => ({
        id: item?.pk.toString(),
        name: item?.name,
        taxonomy: taxonomyList,
        currency: item?.currency_type?.toUpperCase(),
        unit_price: parseFloat(item.price),
        unit_sale_price: parseFloat(item.retail_price),
        url: `${window.location.origin}${item.absolute_url}`,
        stock: item.stock,
        color: item.attributes?.ent_ColorDescription,
        size: item.attributes?.ent_ColorDescription,
        product_image_url: item.productimage_set?.[0]?.image,
        custom: {
          season: item?.attributes?.ent_ProductAtt02
        }
      }))
    };
  }, [products]);

  return null;
}

export function InsiderUserObject({ user }) {
  useEffect(() => {
    (window as any).insider_object = (window as any).insider_object || {};
    (window as any).insider_object.user = {
      uuid: user?.pk.toString(),
      gender: user?.gender,
      birthday: user?.dateOfBirth,
      gdpr_optin: true,
      name: user?.firstName,
      surname: user?.lastName,
      email: user?.email,
      email_optin: user?.emailAllowed,
      phone_number: `+9${user?.phone}`,
      sms_optin: user?.smsAllowed
    };
  });

  return null;
}

export function InsiderBasketObject({ data: basket, breadcrumbList }) {
  const products = basket?.basketitem_set;

  useEffect(() => {
    const taxonomyList = breadcrumbList?.map((breadcrumb) => breadcrumb?.text);
    (window as any).insider_object = (window as any).insider_object || {};
    (window as any).insider_object.basket = {
      currency: basket?.basketitem_set?.[0]?.currency_type.toUpperCase(),
      total: parseFloat(basket?.total_amount),
      line_items: products?.map((item) => ({
        product: {
          id: item?.product?.pk.toString(),
          name: item?.product?.name,
          taxonomy: taxonomyList,
          currency: item?.product?.currency_type?.toUpperCase(),
          unit_price: parseFloat(item?.product?.price),
          unit_sale_price: parseFloat(item?.product?.retail_price),
          url: `${window.location.origin}${item?.product?.absolute_url}`,
          stock: item?.product?.stock,
          color: item?.product?.attributes?.ent_ColorDescription,
          size: item?.product?.attributes?.ent_ProductAtt24,
          product_image_url: item?.product?.productimage_set?.[0]?.image,
          custom: {
            season: item?.product?.attributes?.ent_ProductAtt02
          }
        },
        quantity: item?.quantity,
        subtotal: parseFloat(item?.total_amount)
      }))
    };
  });

  return null;
}

export function InsiderTransactionObject({ data, breadcrumbList }) {
  const order = data?.order;
  const products = data?.order?.orderitem_set;

  useEffect(() => {
    const taxonomyList = breadcrumbList?.map((breadcrumb) => breadcrumb?.text);
    (window as any).insider_object = (window as any).insider_object || {};
    (window as any).insider_object.transaction = {
      order_id: order?.id.toString(),
      currency: order?.currency?.value.toUpperCase(),
      total: parseFloat(order?.amount),
      shipping_cost: parseFloat(order?.shipping_amount),
      delivery: {
        country: order?.billing_address?.country?.code.toUpperCase(),
        city: order?.billing_address?.city?.name,
        district: order?.billing_address?.district?.name
      },
      payment_type: order?.payment_option?.name,
      bank_name: order?.bank?.name,
      line_items: products.map((item) => ({
        product: {
          id: item?.product?.pk.toString(),
          name: item?.product?.name,
          taxonomy: taxonomyList,
          currency: item?.price_currency?.value.toUpperCase(),
          unit_price: parseFloat(item?.price),
          unit_sale_price: parseFloat(item?.retail_price),
          url: `${window.location.origin}${item?.product?.absolute_url}`,
          stock: item?.stock,
          color: item?.product?.attributes?.ent_ColorDescription,
          size: item?.product?.attributes?.ent_ProductAtt24,
          product_image_url: item?.product?.image,
          custom: {
            season: item?.product?.attributes?.ent_ProductAtt02
          }
        },
        quantity: products.filter((p) => p.product?.pk === item.product?.pk)
          .length,
        subtotal: parseFloat(item?.price)
      }))
    };
  });

  return null;
}
