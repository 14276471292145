import { Image } from '@akinon/next/components/image';
import { useLocalization } from '@akinon/next/hooks';
import { Link } from '@theme/components';
import styles from './styles.module.css';
import { twMerge } from 'tailwind-merge';

export const PopularCategories = ({ data }) => {
  const { t } = useLocalization();

  return (
    <div>
      <h2 className="text-sm mb-6">{t('search.popular_categories')}</h2>
      <div className={twMerge("flex gap-x-4 overflow-x-auto lg:grid lg:grid-cols-5", styles.noScrollbar)}>
        {data?.map((category, index) => (
          <div key={index} className='shrink-0'>
            <Link href={category?.value?.url}>
              <Image
                src={category.kwargs?.value?.image?.url}
                alt={category.value?.name}
                width={180}
                height={112}
              />
              <p className="mt-3 text-sm">{category.value?.name}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
