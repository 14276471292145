export default function SearchIcon({ className }: { className: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M15 8.75C15 9.98613 14.6334 11.1945 13.9467 12.2223C13.2599 13.2501 12.2838 14.0512 11.1418 14.5242C9.99974 14.9973 8.74307 15.1211 7.53069 14.8799C6.31831 14.6388 5.20466 14.0435 4.33059 13.1694C3.45651 12.2953 2.86125 11.1817 2.62009 9.96931C2.37894 8.75693 2.50271 7.50027 2.97576 6.35823C3.4488 5.21619 4.24988 4.24007 5.27769 3.55331C6.3055 2.86656 7.51387 2.5 8.75 2.5C9.57076 2.5 10.3835 2.66166 11.1418 2.97575C11.9001 3.28984 12.5891 3.75022 13.1694 4.33058C13.7498 4.91095 14.2102 5.59994 14.5243 6.35823C14.8383 7.11651 15 7.92924 15 8.75Z"
      />
      <path d="M17.9429 17.0574L14.032 13.1464C15.1678 11.7843 15.7346 10.0366 15.6147 8.26716C15.4948 6.49767 14.6972 4.84254 13.388 3.6461C12.0788 2.44966 10.3588 1.80402 8.58564 1.84351C6.81253 1.88299 5.1229 2.60456 3.86826 3.85809C2.61361 5.11163 1.89056 6.80062 1.8495 8.57369C1.80845 10.3468 2.45257 12.0674 3.64785 13.3777C4.84314 14.6879 6.49756 15.4869 8.26694 15.6084C10.0363 15.7299 11.7844 15.1646 13.1476 14.03L17.0585 17.9417C17.1166 17.9998 17.1856 18.0459 17.2614 18.0773C17.3373 18.1087 17.4186 18.1249 17.5007 18.1249C17.5829 18.1249 17.6642 18.1087 17.74 18.0773C17.8159 18.0459 17.8848 17.9998 17.9429 17.9417C18.001 17.8837 18.0471 17.8147 18.0785 17.7389C18.1099 17.663 18.1261 17.5817 18.1261 17.4996C18.1261 17.4174 18.1099 17.3361 18.0785 17.2602C18.0471 17.1844 18.001 17.1154 17.9429 17.0574ZM3.12573 8.74955C3.12573 7.63703 3.45563 6.54949 4.07371 5.62447C4.6918 4.69944 5.5703 3.97847 6.59814 3.55273C7.62597 3.12699 8.75697 3.01559 9.84811 3.23263C10.9393 3.44968 11.9415 3.9854 12.7282 4.77208C13.5149 5.55875 14.0506 6.56102 14.2676 7.65217C14.4847 8.74331 14.3733 9.87431 13.9476 10.9021C13.5218 11.93 12.8008 12.8085 11.8758 13.4266C10.9508 14.0447 9.86325 14.3746 8.75073 14.3746C7.2594 14.3729 5.82962 13.7797 4.77508 12.7252C3.72055 11.6707 3.12739 10.2409 3.12573 8.74955Z" />
    </svg>
  );
}
