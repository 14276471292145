import clsx from 'clsx';
import { Button } from '../button';
import { Icon } from '../icon';
import MenuImages from './menu-images';
import { Link } from '../link';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import React from 'react';
import { closeMobileMenu } from '@akinon/next/redux/reducers/header';
import { useLocalization } from '@akinon/next/hooks';

interface IImages {
  value?: {
    url?: string;
    image?: string;
    title?: string;
  };
  kwargs: {
    data_type?: string;
    value?: {
      image?: {
        url?: string;
        data_type?: string;
        value?: string;
      };
    };
  };
}
interface IAttributes {
  images?: IImages;
  category_id: number;
}
interface IExtraContext {
  attributes: IAttributes;
  numchild: number;
  include_parent: boolean;
}
interface IParent {
  label: string;
  url: string;
  level: number;
  pk: string;
  sort_order: number;
  path: string;
  parent_pk: null;
  parent: null | {
    label: string;
    url: string;
    level: number;
    pk: string;
    sort_order: number;
    path: string;
    parent_pk: null;
    parent: null;
    generator_name: string;
    extra_context: IExtraContext;
  };
  generator_name: string;
  extra_context: IExtraContext;
}
interface IMenu {
  label: string;
  url: string;
  level: number;
  pk: string;
  sort_order: number;
  path: string;
  parent_pk: string;
  parent: IParent;
  generator_name: string;
  extra_context: {
    attributes: {
      category_id: number;
    };
    numchild: number;
    include_parent: boolean;
  };
  children: {
    label: string;
    url: string;
    level: number;
    pk: string;
    sort_order: number;
    path: string;
    parent_pk: string;
    parent: IParent;
    generator_name: string;
    extra_context: IExtraContext;
    children: [];
  }[];
}

interface IMobilSubmenu {
  submenuRef?: React.MutableRefObject<HTMLDivElement>;
  menu: IMenu;
  isSecondLevel?: boolean;
  setSelectedMenu: React.Dispatch<
    React.SetStateAction<{
      secondLevel: null | IMenu;
      thirdLevel: null | IMenu;
    }>
  >;
  minHeight?: number;
  imageList: IImages[];
}

const MobilSubmenu = ({
  submenuRef,
  menu,
  isSecondLevel = false,
  setSelectedMenu,
  minHeight,
  imageList
}: IMobilSubmenu) => {
  const dispatch = useAppDispatch();
  const isMobileMenuOpen = useAppSelector(
    (state) => state.header.isMobileMenuOpen
  );
  const { t } = useLocalization();

  return (
    <div
      ref={submenuRef}
      //Todo: Find Better Solution To Hide Previous SubMenu
      style={{ minHeight: minHeight ?? '100vh' }}
      className={clsx(
        'absolute top-0 left-0 right-0 bg-white invisible opacity-0 transition duration-500 transform translate-x-full pointer-events-auto',
        {
          '!visible !opacity-100 !translate-x-0': menu
        },
        { '!pointer-events-none': !isMobileMenuOpen }
      )}
    >
      <header className="px-3 flex items-center justify-between border-b h-[61px] py-4 mb-6">
        <Button
          appearance="ghost"
          onClick={() => {
            if (isSecondLevel) {
              setSelectedMenu({ secondLevel: null, thirdLevel: null });
            } else {
              setSelectedMenu((prevState) => {
                return {
                  ...prevState,
                  thirdLevel: null
                };
              });
            }
          }}
          className="text-xs font-semibold flex items-center gap-2 !p-0 hover:bg-transparent border-none z-20"
        >
          <Icon
            name="arrow-left-menu"
            size={24}
            className="text-black shrink-0"
          />
        </Button>
        <span className="w-full text-sm text-center font-kronaOne -translate-x-[1.125rem]">
          {menu?.label}
        </span>
      </header>

      <div className="flex flex-col w-full pl-6">
        {/* All Link */}
        <div key={menu?.url} className="flex flex-col">
          <Link
            onClick={() => dispatch(closeMobileMenu())}
            href={menu?.url}
            className={'font-sans text-base font-medium text-primary-400 mb-6'}
          >
            {t('common.all')}
          </Link>
        </div>
        {menu?.children?.map((child) => {
          return (
            <div
              key={`selectedMenu-${child.url}-${child.pk}`}
              className="flex flex-col"
            >
              <Link
                onClick={(e) => {
                  if (isSecondLevel && child?.children?.length > 0) {
                    e.preventDefault();
                    setSelectedMenu((prevState) => {
                      return {
                        ...prevState,
                        thirdLevel: child
                      };
                    });
                  } else {
                    dispatch(closeMobileMenu());
                  }
                }}
                href={child.url}
                className="pb-6 font-sans text-base font-medium"
              >
                {child.label}
              </Link>
            </div>
          );
        })}
      </div>

      <MenuImages
        className="pb-6 mx-6 w-full flex shrink-0 overflow-x-auto"
        imageClassName="w-[14.5rem] h-[22.875rem] flex shrink-0"
        list={imageList}
      />
    </div>
  );
};

export default MobilSubmenu;
